import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import { useStaticQuery, graphql } from "gatsby";
import Info from "../components/case-study/info";
import HeaderCaseStudy from "../components/headers/header-case-study";
import LogoGrundfos from "../assets/images/case-study/grundfos.png"
import BgGrundfos from "../assets/images/case-study/grundfos-bg.png"
import ImgQualityControl from "../assets/images/case-study/quality-control.png"
import ImgPipes from "../assets/images/case-study/pipes.jpg"
import ImgGear from "../assets/images/case-study/gear.jpg"
import TextImage from "../components/case-study/text-image";
import Testimonial from "../components/case-study/testimonial";
import { useTranslation } from "react-i18next";
// Header Content



const QualityControl = () => {
  const { t } = useTranslation()

  const hTitle = `${t('caseStudy.qcc.title')}`;

  const infoContent = {
    industry: `${t('caseStudy.qcc.info.industry')}`,
    wentLive: `${t('caseStudy.qcc.info.wentLive')}`,
    stage: `${t('caseStudy.qcc.info.stage')}`,
    services: [
      {
        service: `${t('caseStudy.qcc.info.services.first')}`
      },
      {
        service: `${t('caseStudy.qcc.info.services.second')}`
      },
    ]
  };

  const summaryContent = {
    title: `${t('caseStudy.qcc.summary.title')}`,
    shortText: `${t('caseStudy.about')}`,
    text: `${t('caseStudy.qcc.summary.text')}`,
    image: ImgQualityControl,
  };

  const aboutProjectContent = {
    title: `${t('caseStudy.qcc.aboutProject.title')}`,
    text: `${t('caseStudy.qcc.aboutProject.firstText')}`,
    secondText: `${t('caseStudy.qcc.aboutProject.secondText')}`,
  };

  const aboutCompanyContent = {
    title: "Slovenia Control",
    shortText: `${t('caseStudy.about')}`,
    text: `${t('caseStudy.qcc.aboutCompany.firstText')}`,
    secondText: `${t('caseStudy.qcc.aboutCompany.secondText')}`,
  };

  const outcomeContent = {
    title: `${t('caseStudy.qcc.outcome.title')}`,
    image: ImgPipes,
    centered: true
  };
  const gearImgContent = {
    image: ImgGear,
    soloImage: true,
    centered: true
  };
  const testimonialContent = {
    fullName: "Predrag Kovačić,",
    position: `${t('caseStudy.qcc.testimonial.position')}`,
    text: `${t('caseStudy.qcc.testimonial.text')}`
  };



  return (
    <Layout>
      <Seo title={hTitle} />
      <HeaderCaseStudy hTitle={hTitle} bgImage={BgGrundfos} logo={LogoGrundfos} />
      <Info infoContent={infoContent} />
      <TextImage textImageContent={summaryContent} />
      <TextImage textImageContent={aboutProjectContent} />
      <TextImage textImageContent={gearImgContent} />
      <TextImage textImageContent={aboutCompanyContent} />
      <TextImage textImageContent={outcomeContent} />
      <Testimonial testimonialContent={testimonialContent} />
    </Layout>
  );
};

export default QualityControl;

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`