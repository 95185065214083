import React from "react";
import { useTranslation } from "react-i18next";

const Info = ({ infoContent }) => {
  const { t } = useTranslation()

  return (<section className="m-info">
    <div className="_wr">
      <div className="_w">
        <div className="_12 _s6 _m3 m-info__itemContent">
          <div className="m-info__item">
            <span className="m-info__item--title">{t('caseStudy.industry')}</span>
            <span className="m-info__item--info">{infoContent.industry}</span>
          </div>
        </div>
        <div className="_12 _s6 _m3 m-info__itemContent">
          <div className="m-info__item">
            <span className="m-info__item--title">{t('caseStudy.wentLive')}</span>
            <span className="m-info__item--info">{infoContent.wentLive}</span>
          </div>
        </div>
        <div className="_12 _s6 _m3 m-info__itemContent">
          <div className="m-info__item">
            <span className="m-info__item--title">{t('caseStudy.stage')}</span>
            <span className="m-info__item--info">{infoContent.stage}</span>
          </div>
        </div>
        <div className="_12 _s6 _m3 m-info__itemContent">
          <div className="m-info__item">
            <span className="m-info__item--title">{t('caseStudy.services')}</span>
            {infoContent.services.map(({ service }, key) => (
              <span className="m-info__item--info" key={key}>{service}</span>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>

  );
};

export default Info;