import React, { Fragment } from "react";
import Image from "gatsby-image";



const TextImage = ({ textImageContent }) => {

  return (
    <section className="m-textImage">
      <div className="_wr">
        <div className={`_w m-textImage__content ${textImageContent.centered ? '-centered' : ''} ${textImageContent.soloImage ? '-soloImage' : ''}`}>
          {textImageContent.image && !textImageContent.centered &&
            <Fragment>
              <div className="_m5">
                <h2>{textImageContent.title}</h2>
                {textImageContent.shortText && <p className="a-text -red">{textImageContent.shortText}</p>}
                <p className="m-textImage__text">{textImageContent.text}</p>
              </div>
              <div className="_m7 m-textImage__image">
                <img src={textImageContent.image} />
              </div>
            </Fragment>
          }
          {!textImageContent.image &&
            <Fragment>
              <div className="_m7">
                <h2>{textImageContent.title}</h2>
                {textImageContent.shortText && <p className="a-text -red">{textImageContent.shortText}</p>}
              </div>
              <div className="_m6">
                <p className="m-textImage__text">{textImageContent.text}</p>
              </div>
              {textImageContent.secondText &&
                <div className="_m6">
                  <p className="m-textImage__text">{textImageContent.secondText}</p>
                </div>
              }
            </Fragment>
          }
          {textImageContent.centered &&
            <Fragment>
              {textImageContent.title &&
                <div className="_m8">
                  <h2>{textImageContent.title}</h2>
                  {textImageContent.shortText && <p className="a-text -red">{textImageContent.shortText}</p>}
                </div>
              }
              {textImageContent.text &&
                <div className="_m10">
                  <p className="m-textImage__text">{textImageContent.text}</p>
                </div>
              }
              {textImageContent.image &&

                <div className="_m12 m-textImage__content--image">
                  <img src={textImageContent.image} />
                </div>
              }
            </Fragment>
          }
        </div>
      </div>
    </section>
  );
}

export default TextImage;
