import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import LastImg from "../../assets/images/case-study/last2.png";

const query = graphql`
  {
    file(relativePath: { eq: "images/case-study/placeholder.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Testimonial = ({ testimonialContent }) => {
  const data = useStaticQuery(query);

  return (
    <section className="m-testimonial">
      <div className="_wr">
        <div className="_w m-testimonial__content">
          <div className="_m4 m-testimonial__content--left">
            <p>{testimonialContent.text}</p>
          </div>
          <div className="_m4 m-testimonial__content--right">
            <h3>{testimonialContent.fullName}</h3>
            <span>{testimonialContent.position}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
