import React from "react";
import PropTypes from "prop-types";

const HeaderCaseStudy = ({ hTitle, bgImage, logo }) => {
  return (
    <header className="m-headerCaseStudy">
      <div className="_wr m-headerCaseStudy__wrapper" style={{ backgroundImage: `url("${bgImage}")` }}>
        <div className="_w m-headerCaseStudy__content">
          <div className="_xs10 _s8 _l7">
            <img src={logo} alt="Customer logo" />
            <h1 className="m-headerCaseStudy__content--title">{hTitle}</h1>
          </div>
        </div>
      </div>
    </header>
  );
};


HeaderCaseStudy.propTypes = {
  hTitle: PropTypes.string,
  hSubtitle: PropTypes.string,
  hTextOne: PropTypes.string,
  hTextTwo: PropTypes.string,
};

export default HeaderCaseStudy;
